import { render, staticRenderFns } from "./IndReferral.vue?vue&type=template&id=4dfc973c"
import script from "./IndReferral.vue?vue&type=script&lang=js"
export * from "./IndReferral.vue?vue&type=script&lang=js"
import style0 from "./IndReferral.vue?vue&type=style&index=0&id=4dfc973c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoAvatar: require('/var/www/udimi-front-outside/releases/813/components/common/CoAvatar.vue').default})
