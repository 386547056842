import { render, staticRenderFns } from "./default.vue?vue&type=template&id=1f472b9f"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoAppDialog: require('/var/www/udimi-front-outside/releases/813/components/common/CoAppDialog.vue').default,CoGreyBox: require('/var/www/udimi-front-outside/releases/813/components/common/CoGreyBox.vue').default,CoConsentUseSite: require('/var/www/udimi-front-outside/releases/813/components/common/CoConsentUseSite.vue').default})
