import { render, staticRenderFns } from "./IndLiveReviews.vue?vue&type=template&id=61c2358e&scoped=true"
import script from "./IndLiveReviews.vue?vue&type=script&lang=js"
export * from "./IndLiveReviews.vue?vue&type=script&lang=js"
import style0 from "./IndLiveReviews.vue?vue&type=style&index=0&id=61c2358e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c2358e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IndLiveReviewCard: require('/var/www/udimi-front-outside/releases/813/components/index/IndLiveReviewCard.vue').default})
