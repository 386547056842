import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _61078260 = () => interopDefault(import('../pages/affiliate.vue' /* webpackChunkName: "pages/affiliate" */))
const _78d72a7a = () => interopDefault(import('../pages/affiliates.vue' /* webpackChunkName: "pages/affiliates" */))
const _3f3a0574 = () => interopDefault(import('../pages/app.vue' /* webpackChunkName: "pages/app" */))
const _b229cb9a = () => interopDefault(import('../pages/awards.vue' /* webpackChunkName: "pages/awards" */))
const _69c52d66 = () => interopDefault(import('../pages/buy-solo-ads.vue' /* webpackChunkName: "pages/buy-solo-ads" */))
const _3c10e12e = () => interopDefault(import('../pages/buy-solo-ads/index.vue' /* webpackChunkName: "pages/buy-solo-ads/index" */))
const _1fe14a57 = () => interopDefault(import('../pages/buy-solo-ads/niche/_slug.vue' /* webpackChunkName: "pages/buy-solo-ads/niche/_slug" */))
const _674ce278 = () => interopDefault(import('../pages/dev-login.vue' /* webpackChunkName: "pages/dev-login" */))
const _32bc62f5 = () => interopDefault(import('../pages/filter.vue' /* webpackChunkName: "pages/filter" */))
const _661048ba = () => interopDefault(import('../pages/forgot/index.vue' /* webpackChunkName: "pages/forgot/index" */))
const _8ecd9498 = () => interopDefault(import('../pages/forum.vue' /* webpackChunkName: "pages/forum" */))
const _11cba537 = () => interopDefault(import('../pages/forum/index.vue' /* webpackChunkName: "pages/forum/index" */))
const _66bb88af = () => interopDefault(import('../pages/forum/comment/_id.vue' /* webpackChunkName: "pages/forum/comment/_id" */))
const _18ad0914 = () => interopDefault(import('../pages/forum/_section/index.vue' /* webpackChunkName: "pages/forum/_section/index" */))
const _8a74a072 = () => interopDefault(import('../pages/forum/_section/_idSlug.vue' /* webpackChunkName: "pages/forum/_section/_idSlug" */))
const _35f84f7e = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _055ebf81 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _ad953384 = () => interopDefault(import('../pages/help/support.vue' /* webpackChunkName: "pages/help/support" */))
const _2e5adb0c = () => interopDefault(import('../pages/help/a/_plink.vue' /* webpackChunkName: "pages/help/a/_plink" */))
const _46b5640c = () => interopDefault(import('../pages/help/_category.vue' /* webpackChunkName: "pages/help/_category" */))
const _3eaf7ffe = () => interopDefault(import('../pages/help/_unregistered/_plink.vue' /* webpackChunkName: "pages/help/_unregistered/_plink" */))
const _50399808 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4435fe30 = () => interopDefault(import('../pages/logo.vue' /* webpackChunkName: "pages/logo" */))
const _3b32858a = () => interopDefault(import('../pages/logos.vue' /* webpackChunkName: "pages/logos" */))
const _5e8d07dc = () => interopDefault(import('../pages/prime.vue' /* webpackChunkName: "pages/prime" */))
const _7e081602 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _70cf0e1e = () => interopDefault(import('../pages/reviews/index.vue' /* webpackChunkName: "pages/reviews/index" */))
const _64f56525 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _50e76cd0 = () => interopDefault(import('../pages/search/_unregistered.vue' /* webpackChunkName: "pages/search/_unregistered" */))
const _70dd3895 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _131ee342 = () => interopDefault(import('../pages/solo-ads/index.vue' /* webpackChunkName: "pages/solo-ads/index" */))
const _5407f79b = () => interopDefault(import('../pages/solodeals.vue' /* webpackChunkName: "pages/solodeals" */))
const _31251dde = () => interopDefault(import('../pages/solodeals/index.vue' /* webpackChunkName: "pages/solodeals/index" */))
const _3adada56 = () => interopDefault(import('../pages/solodeals/price.vue' /* webpackChunkName: "pages/solodeals/price" */))
const _c1851168 = () => interopDefault(import('../pages/solodeals/search.vue' /* webpackChunkName: "pages/solodeals/search" */))
const _d869afea = () => interopDefault(import('../pages/solodeals/_post.vue' /* webpackChunkName: "pages/solodeals/_post" */))
const _20b0b44f = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _20211048 = () => interopDefault(import('../pages/test-apple.vue' /* webpackChunkName: "pages/test-apple" */))
const _d4667976 = () => interopDefault(import('../pages/test-cases.vue' /* webpackChunkName: "pages/test-cases" */))
const _2402f0b8 = () => interopDefault(import('../pages/test-cases/page1.vue' /* webpackChunkName: "pages/test-cases/page1" */))
const _24110839 = () => interopDefault(import('../pages/test-cases/page2.vue' /* webpackChunkName: "pages/test-cases/page2" */))
const _5f463186 = () => interopDefault(import('../pages/test-owl.vue' /* webpackChunkName: "pages/test-owl" */))
const _14f9e528 = () => interopDefault(import('../pages/udimi-reviews-video/index.vue' /* webpackChunkName: "pages/udimi-reviews-video/index" */))
const _043cbc42 = () => interopDefault(import('../pages/competition/hall-of-fame.vue' /* webpackChunkName: "pages/competition/hall-of-fame" */))
const _09012e94 = () => interopDefault(import('../pages/notifications/instantunsubscribe.vue' /* webpackChunkName: "pages/notifications/instantunsubscribe" */))
const _803b1c32 = () => interopDefault(import('../pages/notifications/settings.vue' /* webpackChunkName: "pages/notifications/settings" */))
const _1f27a1f4 = () => interopDefault(import('../pages/profile/custom-route/recommends.vue' /* webpackChunkName: "pages/profile/custom-route/recommends" */))
const _0e3b90e5 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _70bf631d = () => interopDefault(import('../pages/competition/movers-and-shakers/_month.vue' /* webpackChunkName: "pages/competition/movers-and-shakers/_month" */))
const _e25a260e = () => interopDefault(import('../pages/feeds/post/_uid.vue' /* webpackChunkName: "pages/feeds/post/_uid" */))
const _c3b34f60 = () => interopDefault(import('../pages/articles/_plink.vue' /* webpackChunkName: "pages/articles/_plink" */))
const _5e2684b7 = () => interopDefault(import('../pages/ratings/_month.vue' /* webpackChunkName: "pages/ratings/_month" */))
const _b14c2ed0 = () => interopDefault(import('../pages/restore/_token.vue' /* webpackChunkName: "pages/restore/_token" */))
const _5fd19046 = () => interopDefault(import('../pages/reviews/_permalink.vue' /* webpackChunkName: "pages/reviews/_permalink" */))
const _85354f84 = () => interopDefault(import('../pages/share/_uid.vue' /* webpackChunkName: "pages/share/_uid" */))
const _19968aaa = () => interopDefault(import('../pages/solo-ads/_plink.vue' /* webpackChunkName: "pages/solo-ads/_plink" */))
const _144914e6 = () => interopDefault(import('../pages/token/_token.vue' /* webpackChunkName: "pages/token/_token" */))
const _7dbb29c4 = () => interopDefault(import('../pages/udimi-reviews-video/_page.vue' /* webpackChunkName: "pages/udimi-reviews-video/_page" */))
const _8e83d77e = () => interopDefault(import('../pages/solo/_uid/_anySubRoute.vue' /* webpackChunkName: "pages/solo/_uid/_anySubRoute" */))
const _0f2a24e0 = () => interopDefault(import('../pages/_unregistered/index.vue' /* webpackChunkName: "pages/_unregistered/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/affiliate",
    component: _61078260,
    name: "affiliate"
  }, {
    path: "/affiliates",
    component: _78d72a7a,
    name: "affiliates"
  }, {
    path: "/app",
    component: _3f3a0574,
    name: "app"
  }, {
    path: "/awards",
    component: _b229cb9a,
    name: "awards"
  }, {
    path: "/buy-solo-ads",
    component: _69c52d66,
    children: [{
      path: "",
      component: _3c10e12e,
      name: "buy-solo-ads"
    }, {
      path: "niche/:slug?",
      component: _1fe14a57,
      name: "buy-solo-ads-niche-slug"
    }]
  }, {
    path: "/dev-login",
    component: _674ce278,
    name: "dev-login"
  }, {
    path: "/filter",
    component: _32bc62f5,
    name: "filter"
  }, {
    path: "/forgot",
    component: _661048ba,
    name: "forgot"
  }, {
    path: "/forum",
    component: _8ecd9498,
    children: [{
      path: "",
      component: _11cba537,
      name: "forum"
    }, {
      path: "comment/:id?",
      component: _66bb88af,
      name: "forum-comment-id"
    }, {
      path: ":section",
      component: _18ad0914,
      name: "forum-section"
    }, {
      path: ":section/:idSlug",
      component: _8a74a072,
      name: "forum-section-idSlug"
    }]
  }, {
    path: "/help",
    component: _35f84f7e,
    children: [{
      path: "",
      component: _055ebf81,
      name: "help"
    }, {
      path: "support",
      component: _ad953384,
      name: "help-support"
    }, {
      path: "a/:plink?",
      component: _2e5adb0c,
      name: "help-a-plink"
    }, {
      path: ":category",
      component: _46b5640c,
      name: "help-category"
    }, {
      path: ":unregistered/:plink?",
      component: _3eaf7ffe,
      name: "help-unregistered-plink"
    }]
  }, {
    path: "/login",
    component: _50399808,
    name: "login"
  }, {
    path: "/logo",
    component: _4435fe30,
    name: "logo"
  }, {
    path: "/logos",
    component: _3b32858a,
    name: "logos"
  }, {
    path: "/prime",
    component: _5e8d07dc,
    name: "prime"
  }, {
    path: "/profile",
    component: _7e081602,
    name: "profile"
  }, {
    path: "/reviews",
    component: _70cf0e1e,
    name: "reviews"
  }, {
    path: "/search",
    component: _64f56525,
    name: "search",
    children: [{
      path: ":unregistered?",
      component: _50e76cd0,
      name: "search-unregistered"
    }]
  }, {
    path: "/signup",
    component: _70dd3895,
    name: "signup"
  }, {
    path: "/solo-ads",
    component: _131ee342,
    name: "solo-ads"
  }, {
    path: "/solodeals",
    component: _5407f79b,
    children: [{
      path: "",
      component: _31251dde,
      name: "solodeals"
    }, {
      path: "price",
      component: _3adada56,
      name: "solodeals-price"
    }, {
      path: "search",
      component: _c1851168,
      name: "solodeals-search"
    }, {
      path: ":post",
      component: _d869afea,
      name: "solodeals-post"
    }]
  }, {
    path: "/test",
    component: _20b0b44f,
    name: "test"
  }, {
    path: "/test-apple",
    component: _20211048,
    name: "test-apple"
  }, {
    path: "/test-cases",
    component: _d4667976,
    name: "test-cases",
    children: [{
      path: "page1",
      component: _2402f0b8,
      name: "test-cases-page1"
    }, {
      path: "page2",
      component: _24110839,
      name: "test-cases-page2"
    }]
  }, {
    path: "/test-owl",
    component: _5f463186,
    name: "test-owl"
  }, {
    path: "/udimi-reviews-video",
    component: _14f9e528,
    name: "udimi-reviews-video"
  }, {
    path: "/competition/hall-of-fame",
    component: _043cbc42,
    name: "competition-hall-of-fame"
  }, {
    path: "/notifications/instantunsubscribe",
    component: _09012e94,
    name: "notifications-instantunsubscribe"
  }, {
    path: "/notifications/settings",
    component: _803b1c32,
    name: "notifications-settings"
  }, {
    path: "/profile/custom-route/recommends",
    component: _1f27a1f4,
    name: "profile-custom-route-recommends"
  }, {
    path: "/",
    component: _0e3b90e5,
    name: "index"
  }, {
    path: "/competition/movers-and-shakers/:month?",
    component: _70bf631d,
    name: "competition-movers-and-shakers-month"
  }, {
    path: "/feeds/post/:uid?",
    component: _e25a260e,
    name: "feeds-post-uid"
  }, {
    path: "/articles/:plink?",
    component: _c3b34f60,
    name: "articles-plink"
  }, {
    path: "/ratings/:month?",
    component: _5e2684b7,
    name: "ratings-month"
  }, {
    path: "/restore/:token?",
    component: _b14c2ed0,
    name: "restore-token"
  }, {
    path: "/reviews/:permalink",
    component: _5fd19046,
    name: "reviews-permalink"
  }, {
    path: "/share/:uid?",
    component: _85354f84,
    name: "share-uid"
  }, {
    path: "/solo-ads/:plink?",
    component: _19968aaa,
    name: "solo-ads-plink"
  }, {
    path: "/token/:token?",
    component: _144914e6,
    name: "token-token"
  }, {
    path: "/udimi-reviews-video/:page?",
    component: _7dbb29c4,
    name: "udimi-reviews-video-page"
  }, {
    path: "/solo/:uid?/:anySubRoute?",
    component: _8e83d77e,
    name: "solo-uid-anySubRoute"
  }, {
    path: "/:unregistered",
    component: _0f2a24e0,
    name: "unregistered"
  }, {
    path: "/u/:hash",
    component: _09012e94,
    name: "u-hash"
  }, {
    path: "/u/settings/:hash",
    component: _803b1c32,
    name: "u-settings-hash"
  }, {
    path: "/a/:uid",
    component: _0e3b90e5,
    name: "affiliate-regular"
  }, {
    path: "/prime/:uid([a-zA-Z0-9]{5})",
    component: _0e3b90e5,
    name: "affiliate-prime"
  }, {
    path: "/p/:uid_profile",
    component: _7e081602,
    name: "profile-index"
  }, {
    path: "/p/:uid_profile/recommends",
    component: _1f27a1f4,
    name: "profile-recommends"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
